import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const HeroPage2 = ({tag, title, subtitle, children}) => {
    return (
        <section className="px-6 py-24">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">

                    <div className="text-center lg:text-right">
                        <span className="tag">{tag}</span>
                        <h1 className="text-5xl text-gray-800 mb-1">{title}</h1>
                        <h2 className="text-5xl text-persian-green-500 mb-6">{subtitle}</h2>
                        <p>{children}</p>
                    </div>

                    <div className="space-y-6">
                        <StaticImage src="../images/versioni-img.jpeg" alt="Versioni di Thelgo" placeholder="blurred" draggable="false" />
                     </div>
                </div>
            </div>
        </section>
    )
}

export default HeroPage2