import * as React from "react";
import Layout from "../components/layout";
import HeroPage2 from "../components/heroPage-2";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import { Link } from "gatsby";

const Versioni = () => {
  return (
    <Layout>
      <SEO title="Software per telemarketing e call center. Centralino per chiamate inbound e outbound" />
      <HeroPage2
        tag="Innovazione"
        title="Un software per tutte le esigenze"
        subtitle="Usa Thelgo per il tuo telemarketing o per servizi conto terzi"
        image="../images/versioni-back.jpeg"
      >
        Thelgo è il software per call center completo e semplice da utilizzare
        per gestire campagne telefoniche in cloud. CRM produttivo ed utile:
        permette di gestire chiamate inbound e outbound per avere sempre un call
        center efficace. Utile nella gestione della propria attività lavorativa,
        nelle ricerche di mercato o sondaggi, vendite telefoniche,
        pianificazione e gestione degli appuntamenti, Telemarketing,
        Teleselling, recupero crediti, Agent Sale, Customer Care….
        <b className="block mt-6">
          Scopri Thelgo il software necessario per aprire un call center
        </b>
      </HeroPage2>

      <section className="px-6 py-12">
        <div className="container mx-auto">
          <div className="text-center mb-6">
            <span className="tag">Due versioni</span>
            <h3 className="text-3xl text-gray-800">Le versioni di Thelgo</h3>
          </div>
          <div className="space-y-8">
            <div className="flex items-start gap-12 panel flex-wrap">
              <StaticImage
                src="../images/basic_large.png"
                layout="fixed"
                width={250}
                placeholder="blurred"
              />

              <div className="lg:flex-1">
                <h4>Thelgo BASIC</h4>
                <p>
                  La versione basic è totalmente libera dall’interfaccia
                  telefonica, è completa di tutte le funzioni del software ma
                  non è collegata alla fonia. Con la versione basic è possibile
                  organizzare le campagne per i propri operatori i quali possono
                  impostare l’esito di ogni chiamata ma con questa versione non
                  è possibile rilevare i tempi dettagliati delle conversazioni
                  telefoniche. E’possibile comunque lanciare automaticamente le
                  chiamate tramite comando web (solo per i Softphone che lo
                  supportano come ad esempio Skype tramite il comando
                  skype:[numero telefonico]).
                </p>
              </div>
            </div>

            <div className="flex items-start gap-12 panel flex-wrap">
              <StaticImage
                src="../images/flat-large.png"
                layout="fixed"
                width={250}
                placeholder="blurred"
              />
              <div className="lg:flex-1">
                <h4>Thelgo FLAT</h4>
                <p className="mb-3">
                  <b>
                    La versione FLAT include tutte le funzioni della versione
                    BASIC con in aggiunta il modulo per il Predictive Dialing e
                    Traffico Incluso:
                  </b>
                </p>

                <p>
                  Con il modulo predictive dialing è possibile ottimizzare il
                  numero delle chiamate del call center ed aumentare la
                  produttività degli operatori riducendo il tempo di attesa. Il
                  sistema di chiamate automatiche del software integrato con il PBX Cloud, permette di impostare il numero di
                  chiamate e fornire agli operatori solo le chiamate andate a
                  buon fine. Tramite un sofisticato algoritmo, il software
                  predictive dialing, lancia un numero superiore (circa il
                  doppio) di chiamate rispetto agli operatori liberi, senza
                  pesare sulla connettività grazie al cloud computing. Nel caso
                  in cui la chiamata vada a buon fine viene trasferita
                  immediatamente all’operatore senza ritardi grazie alla
                  risposta automatica ; nel caso il numero risulti occupato o
                  non risponda il sistema richiama lo stesso dopo un tempo
                  variabile in modo da contattare il numero sempre in orari
                  diversi. Il modulo predictive call di Thelgo, gestisce i
                  richiamare sia in modalità privata che ridistribuita: in
                  modalità privata, una volta impostata la data e l’ora del
                  richiamare, verrà riproposto il nominativo solo all’operatore
                  che lo aveva impostato; in modalità ridistribuita, una volta
                  impostata la data e l’ora del contatto da richiamare, il
                  software Crm Thelgo contatterà automaticamente il cliente
                </p>
              </div>
            </div>
          </div>
          <div className="text-center mt-8">
            <Link
              to="/demo"
              className="button-lg bg-persian-green-500 inline-flex text-white hover:bg-persian-green-700 transition-colors"
            >
              Richiedi una demo
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Versioni;
